import { ref, computed } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import managerStoreModule from '../managerStoreModule';

export default function useManagernListModal(props) {
  const STORE_MODULE_NAME = "facility-manager";

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, managerStoreModule);
  }

  const toastification = toast();

  const facilityItem = computed(() => props.facility);

  const blankItem = {
    facility: null,
    full_name: '',
    phone: '',
    email: '',
    status: '',
  };
  const refModal = ref(null);
  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItemLocal = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };
  const data = ref([]);

  const resetModal = () => {
    blankItem.facility = props.facility;
    data.value = [];
    resetItemLocal();
  };

  const fetchData = () => {
    store.dispatch('facility-manager/fetchManagers', { medical_facility: facilityItem.value._id, start: 0, length: 1000 }).then(response => {
      data.value = response.data.data;
    }).catch(error => {
      toastification.showToastError(error);
    });
  };

  const onOpen = () => {
    resetModal();
    fetchData();
  };

  const onEditItem = val => {
    console.log(val);
    if (val) {
      item.value = { ...val, facility: facilityItem.value };
    } else {
      item.value = blankItem;
    }
  };

  const onDelete = val => {
    store.dispatch('facility-manager/deleteManager', val._id).then(() => {
      toastification.showToastDeleteSuccess();
      fetchData();
    }).catch(error => {
      toastification.showToastError(error);
    });
  };

  return {
    refModal,
    facilityItem,
    data,
    fetchData,
    onOpen,
    item,
    onEditItem,
    onDelete,
    resetItemLocal,
    resetModal,

  };
}
